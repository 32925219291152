"use strict";
exports.__esModule = true;
var co_urlnavigator_1 = require("../../../../../../controller/static/ts/co.urlnavigator");
var Webapp = (function () {
    function Webapp() {
    }
    Webapp.scrollToMainContent = function () {
        co_urlnavigator_1.COURLNavigator.Controller.gotoBlock('main-content');
    };
    return Webapp;
}());
exports.Webapp = Webapp;
